import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import plugins from "./plugins/app";
import "vue-notifyjs/themes/default.css";
import jQuery from "jquery";
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons';
import { Modal } from "usemodal-vue3";
import VueTagsInput from 'vue3-tags-input';
import Select2 from 'vue3-select2-component';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

const $app = {};

const app = createApp(App).use(router);

// $app.baseDataUrl = "http://localhost:2337";
$app.baseDataUrl = "https://shohadaa.your-guide.co";

app.provide("$app", $app);
app.use(plugins);
app.use({
  install: function () {
    app.$jQuery = jQuery;
    window.$ = window.jQuery = window.jquery = jQuery;
  },
});
app.component('BootstrapIcon', BootstrapIcon);
app.component('v-modal', Modal);
app.component('tags-input', VueTagsInput);
app.component('Select-2', Select2);

router.isReady().then(() => {
  app.mount("#app");
});
