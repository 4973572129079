import cookies from "@/services/cookies";

export default {
  isLoggedIn() {
    return cookies.getJSONCookie("isLoggedIn");
  },
  getUserInfo() {
    return cookies.getJSONCookie("userInfo");
  },
  rememberUser() {
    return cookies.getJSONCookie("rememberMe");
  },
  login(loginResponse) {
    cookies.setJSONCookie("isLoggedIn", true);
    cookies.setJSONCookie("userInfo", loginResponse.user);
    cookies.setCookie("jwt", loginResponse.jwt);
  },
  logout() {
    cookies.clearCookie("userInfo");
    cookies.clearCookie("rememberMe");
    cookies.clearCookie("isLoggedIn");
  },
  getToken() {
    return cookies.getCookie("jwt");
  }
};
