<template>
  <div onunload="unload()">
    <router-view :isLoggedIn="isLoggedIn" :globalData="globalData"></router-view>
    <!-- <div v-if="!isLoggedIn" class="position-absolute bottom-0 end-0 m-3" v-on:click="showLogin = !showLogin;">
      <button type="button" class="btn btn-success btn-circle">
        <BootstrapIcon icon="box-arrow-in-right" />
      </button>
    </div>
    <div v-if="isLoggedIn" class="position-absolute bottom-0 end-0 m-3" v-on:click="showAdd = !showAdd">
      <button type="button" class="btn btn-success btn-circle">
        <BootstrapIcon icon="plus" />
      </button>
    </div> -->
  </div>
  <!-- <login-modal v-if="showLogin" :globalData="globalData" v-on:closed="showLogin = false; reCheckLogIn();"/> -->
  <!-- <add-entity v-if="showAdd" :globalData="globalData" v-on:closed="showAdd = false;"/> -->
  <notifications />
</template>

<script>
import { inject } from "vue";
// import LoginModal from "@/components/Login/Login";
// import AddEntity from "@/components/AddEntity";
import loginUtil from "@/components/Login/util";

export default {
  // components: { LoginModal, AddEntity },
  data() {
    return {
      showAdd: false,
      showLogin: false,
    }
  },
  setup() {
    let isLoggedIn = loginUtil.isLoggedIn();
    let $app = inject("$app");
    return { globalData: $app, isLoggedIn: !!isLoggedIn };
  },
  mounted() {
    // TODO: check log-in if needed
  },
  methods: {
    reCheckLogIn() {
      this.isLoggedIn = loginUtil.isLoggedIn();
    },
    unload() {
      if(!loginUtil.rememberUser()) loginUtil.logout();
    }
  }
};
</script>

<style lang="scss">
.btn-circle.btn-xl {
  width: 100px;
  height: 100px;
  padding: 13px 18px;
  border-radius: 60px;
  font-size: 15px;
  text-align: center;
}

.vue-notifyjs.notifications {
  .alert {
    z-index: 10000;
  }

  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }

  .list-item {
    display: inline-block;
    margin-right: 10px;
  }

  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }

  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>
