<template>
  <nav id="navbar" class="navbar bg-body-tertiary px-3 mb-3" style="
        color: white;
        background-image: linear-gradient(
          to right,
          #111b58,
          #111b58,
          #111b58,
          #111b58,
          #111b58,
          #171d5d,
          #1c2061,
          #212266,
          #2c2771,
          #382c7b,
          #433186,
          #4f3690
        );
      ">
    <a class="navbar-brand col-md-6" style="color: white;" href="#">Fund Requests</a>
  </nav>
  <div class="m-3">
    <div class="alert alert-warning" role="alert">
      <strong>Disclaimer!</strong> This is just a list of Palestinian fundraisers, it is still encouraged to get into
      contact with them and check their socials before donating
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-2">
      <div class="container">
        <div class="card text-center" style="display: flex; align-items: center;">
          <div class="card-header" style="width: 100%">Palestine red crescent society</div>
          <img src="https://palestinercs.org/public/images/headerLogoen1.png" class="card-img-top mt-2"
            alt="Palestine red crescent society" style="max-height: 250px; width: 80%; object-fit:cover;" />
          <div class="card-body">
            <a href="https://palestinercs.org/ar" target="_blank" class="card-link">Let's Go</a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="container-fluid"> -->
    <!-- Main Content -->
    <div role="main" class="container col-sm-12 col-md-12 col-lg-10 g-4">
      <div v-if="!fundRequests.length" class="text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">loading...</span>
        </div>
      </div>


      <div v-if="fundRequests.length" class="row row-cols-1 row-cols-sm-1 row-cols-md-3 row-cols-lg-5 g-4">
        <div class="col" v-for="oneRequest in fundRequests" :key="oneRequest._id">
          <div class="card h-100 flex-container">
            <img v-if="oneRequest.thumbnail" :src="oneRequest.thumbnail" class="card-img-top card-img-top-cols"
              :alt="oneRequest.name" style="max-height: 250px; width: 100%; object-fit:cover;" />
            <div class="card-body">
              <h5 class="card-title">{{ oneRequest.name }}</h5>
              <p class="card-text">
                {{ oneRequest.bio.slice(0, 100) }}<span v-if="oneRequest.bio.length > 100">...</span>
              </p>
            </div>
            <div class="card-footer">
              <div class="row">
                <small class="col-md-6 col-12 pull-left">
                  <a style="width: 100%" v-on:click="clickDescription(oneRequest)"
                    class="btn btn-primary pull-left">Details</a>
                </small>
                <small class="col-md-6 col-12 pull-right">
                  <a style="width: 100%" v-on:click="clickUrl(oneRequest)" class="btn btn-success pull-right">Fund</a>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="fundRequests.length && paging.loadMore" class="row flex-container" style="height: 100px; margin: 20px;">
        <div class="text-center col-12 mv-lg flex-item">
          <button class="btn btn-info btn-oval btn-lg" type="button" style="border-radius: 50px;"
            @click="loadMore"><span v-if="data_loading_more" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Load more..</button>
        </div>
      </div>
    </div>
  </div>

  <v-modal v-model:visible="showDescription" :title="'Details'" width="800px">
    <div class="card">
      <div class="card-body row">
        <h5 class="card-title col-sm-12">{{ fund.name }}</h5>
        <div class="col-sm-4"><img v-if="fund.cover" :src="fund.cover" :alt="fund.name" class="card-img-bottom" />
        </div>
        <div class="col-sm-8">
          <p class="card-text">
            <vue-markdown :source="fund.bio" :plugins="plugins" />
          </p>
          <p class="card-text">
            Email:<small class="text-body-secondary">{{ fund.email || '-' }}</small>
          </p>
          <p class="card-text">
            Amount:<small class="text-body-secondary">{{ fund.amount || '-' }}</small>
          </p>
          <p class="card-text">
            <small class="col-md-6 pull-right">
              <a v-on:click="clickUrl(fund)" class="btn btn-success col-md-12 pull-right">Fund
              </a>
            </small>
          </p>
        </div>
      </div>
      <div class="row" v-if="fund.photos && fund.photos.length">
        <div class="col-sm-2"></div>
        <div id="carousel" class="col-sm-8">
          <Carousel id="gallery" :items-to-show="1" :wrap-around="true" v-model="currentSlide">
            <Slide v-for="onePhotoItem in fund.photos" :key="onePhotoItem.hash">
              <img class="carousel__item d-block w-100" :src="onePhotoItem.url" :alt="onePhotoItem.hash" />
            </Slide>
            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>
        </div>
        <div class="col-sm-2"></div>
        <div id="carousel" class="col-sm-12">
          <Carousel id="thumbnails" :items-to-show="4" :wrap-around="true" v-model="currentSlide" ref="carousel">
            <Slide v-for="(onePhotoItem, slide) in fund.photos" :key="onePhotoItem.hash">
              <img class="carousel__item d-block w-100" :src="onePhotoItem.url" :alt="onePhotoItem.hash"
                @click="slideTo(slide - 1)" />
            </Slide>
          </Carousel>
        </div>
      </div>

    </div>
  </v-modal>
</template>

<script>
  import _ from "lodash";
  import apiService from "../services/api";
  import Swal from "sweetalert2";
  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
  import VueMarkdown from 'vue-markdown-render'
  import MarkdownItAnchor from 'markdown-it-anchor';

  const ICO = "https://res.cloudinary.com/dvj355d2m/image/upload/v1703956595/favicon_7dd6815cfd.ico";
  
  export default {
    name: "fund-page",
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation,
      VueMarkdown,
    },
    props: ['globalData'],
    mounted() {
      this.fundId = this.$route.query.fundId;
      this.fetchData();
    },
    data() {
      return {
        paging: { page: 0, loadMore: true },
        plugins: [MarkdownItAnchor],
        currentSlide: 0,
        fundId: null,
        clickedImg: null,
        fund: this.fund || { name: "Details" },
        fundRequests: [],
        showDescription: false,
        data_loading: true,
        data_loading_more: false,
      };
    },
    methods: {
      slideTo(val) {
        this.currentSlide = val
      },
      clickUrl(clickedEle) {
        window.open(clickedEle.url, "_blank");
      },
      clickDescription(clickedEle) {
        this.fund = clickedEle;
        this.showDescription = true;
      },
      loadMore() {
        if (this.data_loading_more) return;
        this.paging.page = this.paging.page + 1;
        this.data_loading_more = true;
        this.fetchData();
      },
      async fetchData() {
        let pagingInfo = `pagination[start]=${this.paging.page * 10}&pagination[limit]=10`;
        let query = `populate=cover,photos&${pagingInfo}`;
        await apiService
          .get(this.globalData.baseDataUrl, `/api/fund-requests?${query}`)
          .then((response) => {
            this.data_loading = false;
            this.data_loading_more = false;
            // {
            //     "name": "this is full name",
            //     "bio": null,
            //     "age": null,
            //     "birth": null,
            //     "gender": null,
            //     "registeredAt": null,
            //     "tags": null,
            //     "createdAt": "2023-12-30T17:00:01.456Z",
            //     "updatedAt": "2023-12-30T17:00:01.456Z",
            //     "publishedAt": "2023-12-30T17:00:01.454Z",
            //     "cover": {
            //         "data": null
            //     }
            // }

            if (response.data.length < 10) this.paging.loadMore = false;
  
            let newRequests = response.data.map((s) => ({
              ...s.attributes,
              bio: s.attributes.bio || "Bio unavailable",
              _id: s.id,
              thumbnail: s.attributes.cover && s.attributes.cover.data ? _.result(s, "attributes.cover.data.attributes.formats.thumbnail.url") : ICO,
              cover: s.attributes.cover && s.attributes.cover.data ? _.result(s, "attributes.cover.data.attributes.url") : ICO,
              photos: s.attributes.photos && s.attributes.photos.data ? s.attributes.photos.data.map(d => d.attributes) : [],
            }));

            this.fundRequests.push(...newRequests);
          })
          .catch((err) => {
            Swal.fire(err.message);
            console.error(err);
          });
      },
    },
  };
  </script>
<style>
.card-img-top-cols {
  height: 250px;
}

.h-100 {
  height: 100%;
}

.flex-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.flex-item {
  flex: 1;
  overflow: auto;
  flex: 1;
}

@media all and (min-width:769px) {
  /* wider than tablet (labtop) => lg */
}

@media all and (min-width:320px) and (max-width: 768px) {

  /* larget phones to tablet size => md */
  .modal-vue3-content {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .col-12 {
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .col {
    padding: 15px;
  }
}

@media all and (min-width:0px) and (max-width: 320px) {
  /* small phone => sm */

  .modal-vue3-content {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .col {
    padding: 20px;
  }
}

/*

xs (for phones - screens less than 768px wide)
sm (for tablets - screens equal to or greater than 768px wide)
md (for small laptops - screens equal to or greater than 992px wide)
lg (for laptops and desktops - screens equal to or greater than 1200px wide)

*/
</style>
