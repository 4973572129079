module.exports = {
  /**
   * Set Cookie
   * @param {String} cname
   * @param {String} cvalue
   * @param {Number} exdays
   */
  setCookie: function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";";
  },

  setJSONCookie: function (cname, cvalue, exdays) {
    let json = JSON.stringify(cvalue);
    this.setCookie(cname, json, exdays);
  },

  getJSONCookie: function (cname) {
    let cvalue = this.getCookie(cname);
    return cvalue ? JSON.parse(cvalue) : cvalue;
  },

  getAllCookies: function () {
    var cookies = {};
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i].trim();
      var separatorIndex = cookie.indexOf("=");

      if (separatorIndex !== -1) {
        var name = cookie.substring(0, separatorIndex);
        var value = cookie.substring(separatorIndex + 1);

        // Trim leading spaces in name
        name = name.trim();

        cookies[name] = value;
      }
    }

    return cookies;
  },

  getCookie: function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i].trim();

      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length);
      }
    }

    return null;
  },

  clearCookie: function (cname) {
    document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  },
};
