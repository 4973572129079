<template>
  <nav id="navbar" class="navbar bg-body-tertiary px-3 mb-3" style="
      color: white;
      background-image: linear-gradient(
        to right,
        #111b58,
        #111b58,
        #111b58,
        #111b58,
        #111b58,
        #171d5d,
        #1c2061,
        #212266,
        #2c2771,
        #382c7b,
        #433186,
        #4f3690
      );
    ">
    <a class="navbar-brand col-md-6" style="color: white;" href="#">Shohadaa Hall</a>
  </nav>

  <div class="container">
    <!-- <div class="container-fluid"> -->
    <!-- Main Content -->
    <main role="main">
      <div v-if="!shohadaa" class="text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">يتم التحميل الان...</span>
        </div>
      </div>

      <div v-if="shohadaa">
        <div class="row row-cols-1 row-cols-md-5 g-4">
          <div class="col" v-for="oneShaheed in shohadaa" :key="oneShaheed._id">
            <div class="card h-100">
              <img v-if="oneShaheed.thumbnail" :src="oneShaheed.thumbnail" class="card-img-top" :alt="oneShaheed.name" />
              <div class="card-body">
                <h5 class="card-title">{{ oneShaheed.name }}</h5>
                <p class="card-text">
                  {{ oneShaheed.bio.slice(0, 100) }}<span v-if="oneShaheed.bio.length > 100">...</span>
                </p>
              </div>
              <div class="card-footer">
                <small class="text-body-secondary"><a v-on:click="clickDescription(oneShaheed)"
                    class="btn btn-primary">Details</a></small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>

  <v-modal v-model:visible="showDescription" :title="shaheed.name" width="800px">
    <div class="card">
      <div class="card-body row">
        <h5 class="card-title col-sm-12">{{ shaheed.name }}</h5>
        <div class="col-sm-4"><img v-if="shaheed.img" :src="shaheed.img" :alt="shaheed.name" class="card-img-bottom" />
        </div>
        <div class="col-sm-8">
          <p class="card-text">
            <vue-markdown :source="shaheed.bio" :plugins="plugins"/>
          </p>
          <p class="card-text">
            Registered At:<small class="text-body-secondary">{{ shaheed.registeredAt || '-' }}</small>
          </p>
        </div>
      </div>
      <div class="row" v-if="shaheed.gallery && shaheed.gallery.length">
        <div id="carousel" class="col-sm-12">
          <carousel :items-to-show="1.5">
            <slide v-for="oneGalleryItem in shaheed.gallery" :key="oneGalleryItem.hash">
              <img class="d-block w-100" :src="oneGalleryItem.url" :alt="oneGalleryItem.hash">
            </slide>
            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </div>
      </div>

    </div>
  </v-modal>
</template>

<script>
import _ from "lodash";
import apiService from "../services/api";
import Swal from "sweetalert2";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import VueMarkdown from 'vue-markdown-render'
import MarkdownItAnchor from 'markdown-it-anchor';

const ICO = "https://res.cloudinary.com/dvj355d2m/image/upload/v1703956595/favicon_7dd6815cfd.ico";

export default {
  name: "Shohadaa-page",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    VueMarkdown,
  },
  props: ['isLoggedIn', 'globalData'],
  mounted() {
    this.shaheedId = this.$route.query.shaheedId;
    this.fetchData();
  },
  data() {
    return {
      plugins: [MarkdownItAnchor],
      shaheedId: null,
      clickedImg: null,
      shaheed: this.shaheed || { name: "Details" },
      shohadaa: null,
      showDescription: false,
    };
  },
  methods: {
    clickDescription(clickedEle) {
      this.shaheed = clickedEle;
      this.showDescription = true;
    },
    async fetchData() {
      this.data_loading = true;
      this.shohadaa = null;
      await apiService
        .get(this.globalData.baseDataUrl, `/api/shohadaas?populate=img,gallery`)
        .then((response) => {
          this.data_loading = false;
          // {
          //     "name": "this is full name",
          //     "bio": null,
          //     "age": null,
          //     "birth": null,
          //     "gender": null,
          //     "registeredAt": null,
          //     "tags": null,
          //     "createdAt": "2023-12-30T17:00:01.456Z",
          //     "updatedAt": "2023-12-30T17:00:01.456Z",
          //     "publishedAt": "2023-12-30T17:00:01.454Z",
          //     "img": {
          //         "data": null
          //     }
          // }

          this.shohadaa = response.data.map((s) => ({
            ...s.attributes,
            bio: s.attributes.bio || "Bio unavailable",
            _id: s.id,
            thumbnail: s.attributes.img && s.attributes.img.data ? _.result(s, "attributes.img.data.attributes.formats.thumbnail.url") : ICO,
            img: s.attributes.img && s.attributes.img.data ? _.result(s, "attributes.img.data.attributes.url") : ICO,
            gallery: s.attributes.gallery && s.attributes.gallery.data ? s.attributes.gallery.data.map(d => d.attributes) : [],
          }));
        })
        .catch((err) => {
          Swal.fire(err.message);
          console.error(err);
        });
    },
  },
};
</script>
<style scoped></style>
