import axios from "axios";

export default {
  queryObjectToStr(obj) {
    let arr = [];
    for (let key in obj) {
      if(obj[key] != null && obj[key] != undefined) arr.push(`${key}=${obj[key]}`);
    }
    return arr.join("&");
  },

  async get(baseUrl, link, auth) {
    const response = await axios.get(`${baseUrl}${link}`, {
      headers: {
        "Content-Type": "Application/json",
        ...(auth ? { Authorization: auth } : false),
      },
    });
    return response.data;
  },
  async patch(baseUrl,link,body,auth){
    const response = await axios.patch(`${baseUrl}${link}`, body, {
      headers: {
        "Content-Type": "Application/json",
        ...(auth ? { Authorization: auth } : false),
      },
    });
    return response.data;
  },
  async post(baseUrl, link, body, auth, fullResponse) {
    const response = await axios.post(`${baseUrl}${link}`, body, {
      headers: {
        "Content-Type": "Application/json",
        ...(auth ? { Authorization: auth } : false),
      },
    });
    return fullResponse ? response : response.data;
  },

  async postMultipart(baseUrl, link, formData, auth) {
    const response = await axios.post(`${baseUrl}${link}`, formData, {
      headers: { ...(auth ? { Authorization: auth } : false) },
    });
    return response.data;
  },

  async putMultipart(baseUrl, link, formData, auth) {
    const response = await axios.put(`${baseUrl}${link}`, formData, {
      headers: { ...(auth ? { Authorization: auth } : false) },
    });
    return response.data;
  },

  async put(baseUrl, link, body, auth) {
    const response = await axios.put(`${baseUrl}${link}`, body, {
      headers: {
        "Content-Type": "Application/json",
        ...(auth ? { Authorization: auth } : false),
      },
    });
    return response.data;
  },

  async delete(baseUrl, link, auth, body) {
    const response = await axios({
      headers: {
        "Content-Type": "Application/json",
        ...(auth ? { Authorization: auth } : false),
      },
      data: body,
      url: `${baseUrl}${link}`,
      method: "DELETE",
    });
    return response.data;
  },

  async getFile(baseUrl, link, auth) {
    const response = await axios.get(`${baseUrl}${link}`, {
      headers: {
        "Content-Type": "Application/json",
        ...(auth ? { Authorization: auth } : false),
      },
      encoding: null,
    });
    return response.data;
  },
};
