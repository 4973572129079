<template>
    <div class="row">
        <div class="col-lg-12 text-center" style="margin-top: 15%">
            <div class="contact-us full-screen text-center">
                <div class="wrapper wrapper-full-page section content">
                    <div class="">
                        <div class="container">
                            <div class="text-center">
                                <h2 class="title">redirecting.</h2>
                                <div class="spinner-grow text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-secondary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-success" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-danger" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-warning" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-info" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow text-dark" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer class="footer">
            <div class="container">
                <nav class="pull-left">
                    <ul>
                        <li>
                            <router-link :to="{ path: '/fund' }">Fundraiser Gallery</router-link>
                        </li>
                        <li>
                            <router-link :to="{ path: '/shohadaa' }">Shohadaa Hall</router-link>
                        </li>
                    </ul>
                </nav>
                <div class="copyright pull-right">
                    &copy; 2023, made with
                    <BootstrapIcon icon="heart-fill" /> by Ashar
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
  export default {
    mounted() {
        let href = window.location.href;
        if (href.match(/gallery/i)) this.$router.push('/fund');
        if (href.match(/shohadaa/i)) this.$router.push('/shohadaa');
    }
  };
  </script>
<style scoped></style>