<template>
  <div class="row">
    <div class="col-lg-12 text-center" style="margin-top: 15%">
      <div class="contact-us full-screen text-center">
        <div class="wrapper wrapper-full-page section content">
          <div class="">
            <div class="container">
              <div class="text-center">
                <h2 class="title text-danger">404 Not Found</h2>
                <h2 class="title">Oops! It seems that this page does not exist.</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer-demo">
      <div class="container">
        <nav class="pull-left">
          <ul>
            <li>
              <router-link :to="{ path: '/fund' }">Fundraiser Gallery</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/shohadaa' }">Shohadaa Hall</router-link>
            </li>
          </ul>
        </nav>
        <div class="copyright pull-right">
          &copy; 2023, made with
          <BootstrapIcon icon="heart-fill" /> by Ashar
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.notFound {
  background: #f5f7fa;
}
</style>
