import { createRouter, createWebHistory } from "vue-router";
import NotFound from "@/pages/NotFoundPage.vue";
import ShohadaaPage from "@/pages/ShohadaaPage.vue";
import FundPage from "@/pages/FundPage.vue";
import RedirectPage from "@/pages/RedirectPage.vue";

// configure router
const routes = [
  {
    path: "/",
    component: RedirectPage,
  },
  {
    path: "/fund",
    component: FundPage,
  },
  {
    path: "/shohadaa",
    component: ShohadaaPage,
  },
  { path: '/:pathMatch(.+)',
  component: NotFound },
];

const router = createRouter({ history: createWebHistory(), routes/* , base: '/' */ });
export default router;
